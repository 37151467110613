import { Spinner } from '@red-ui/components'
import { getFeatureIsLoading, useFeatureSelector } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from '@/components'
import { useAppSelector } from '@/redux/hooks/hooks'
import { PATHS } from '@/router/paths'
import { Redirect } from '@/router/redirect'
import { LandingPage } from './pages/LandingPage'
import { SearchResults } from './pages/SearchResults'

export function RedSkyRouter() {
  const isEnabled = useAppSelector(useFeatureSelector(FeatureName.WEB_REDSKY_SEARCH))
  const featureFlagsLoading = useAppSelector(getFeatureIsLoading)

  // note: we should ideally have this higher up in the component tree
  if (featureFlagsLoading) return <Spinner size="large" color="$red6" height="100vh" justifyContent="center" />
  if (!isEnabled) return <Redirect to={PATHS.HOME} />

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <LandingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/results"
        element={
          <PrivateRoute>
            <SearchResults />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}
