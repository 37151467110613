import { H1, Paragraph, YStack, Accordion, Anchor } from '@red-ui/components'
import React, { useState } from 'react'
import { Page } from '@/components'
import { FlightDataCard } from '../components/FlightDataCard'
import { getString } from '@vrw/data'

export const flightsData = [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }]

export function SearchResults() {
  const [activeItem, setActiveItem] = useState<string | undefined>()

  return (
    <Page title="red sky search results">
      <YStack tag="section" gap="$5" paddingHorizontal="$4" paddingVertical="$6">
        <YStack alignItems="center" gap="$2">
          <H1>Manchester to New York</H1>
          <Paragraph>
            {`February 2025 | `}
            <Anchor cursor="pointer" color="$red6">
              {getString('redSkySearch.results.date.change')}
            </Anchor>
          </Paragraph>
        </YStack>
        <YStack alignItems="center" gap="$2">
          <Accordion value={activeItem} onValueChange={setActiveItem} type="single" collapsible gap="$2">
            {flightsData.map(({ id }) => (
              <FlightDataCard key={id} value={id} isActive={id === activeItem} />
            ))}
          </Accordion>
        </YStack>
      </YStack>
    </Page>
  )
}
