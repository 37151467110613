import {
  Button,
  ButtonProps,
  Icon,
  Nav,
  SizableText,
  XStack,
  YStack,
  Adapt,
  Popover,
  Separator,
  getTokenValue,
  useMedia,
  styled,
  AnchorProps,
  SizableTextProps,
} from '@red-ui/components'
import { getString } from '@vrw/data'
import { doLoginWeb, doLogoutWeb } from '@vrw/data/src/redux/auth/dispatchers'
import { getBalance, getError, getIsLoading } from '@vrw/data/src/redux/balance/balance.selectors'
import { fetchBalance } from '@vrw/data/src/redux/balance/balance.thunk'
import { formatPoints } from '@vrw/data/src/utils/formatters'
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useMatch } from 'react-router-dom'
import { getPath, regionalizePath } from '../../dataImplementation/viewingRegionWeb'
import { setDataLayer } from '../../helpers/cookiePreferences'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { EXTERNAL_PATHS, PATHS } from '../../router/paths'
import { SkipToMainLink } from '../Buttons/Link/SkipToMainLink'
import { Footer } from '../Footer'
import { RedFooter } from '../RedFooter'
import { RegionSelectorBanner } from '../RegionSelectorBanner/'
import { SeoHeaders } from '../SeoHeaders'
import { Search } from './Search'
import { PageProps } from './types'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { ViewingRegion, logger } from '@vrw/data/src/utils'
import { selectFeatureById } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { useFeatures, useCampaignId } from './hooks'
import { Link } from '../Link'
import { getAuthTokens, setAuthTokens } from '@vrw/data/src/redux/auth/getAuthTokens'
import { doFetchProfile } from '@vrw/data/src/redux/profile/dispatchers'
import { useUserSubscriptionStatus } from '../../query/subscriptions'

const MenuLink = styled(Link, {
  cursor: 'pointer',
  size: '$2',
  padding: '$2',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  group: 'MenuLink',
  fontWeight: '500',
  hoverStyle: {
    color: '$red6',
  },
  style: {
    textDecoration: 'none',
  },
})

const NavButton = styled(Button, {
  size: '$9',
  paddingHorizontal: '$1',
  borderWidth: '$0',
  borderRadius: '$0',
  scaleSpace: 0,
  style: {
    textDecoration: 'none',
  },
  fontSize: '$3',
  letterSpacing: 1,
  fontWeight: '500',
  scaleIcon: 0.9,
  textProps: {
    textTransform: 'uppercase',
    $gtMobile: {
      letterSpacing: 2,
    },
  },
  outlineOffset: '$-0.25',
})

const NavButtonLink = NavButton.styleable<void, ButtonProps & AnchorProps>(({ href, target, children, ...props }, ref) => {
  const match = useMatch(href || '')

  return (
    <Link href={href} target={target} asChild="except-style">
      <NavButton
        width="$10"
        $gtMobile={{
          width: '$16',
        }}
        $gtTabletLandscape={{
          width: '$20',
        }}
        {...props}
        ref={ref}>
        {children}
        {match && <XStack position="absolute" bottom={'$0'} left={'$0'} right={'$0'} height={'$0.25'} backgroundColor="$white" />}
      </NavButton>
    </Link>
  )
})

const Balance = ({ color = '$white' }: Partial<Pick<SizableTextProps, 'color'>>) => {
  const balance = useAppSelector(getBalance)
  const isLoading = useAppSelector(getIsLoading)
  const isError = useAppSelector(getError)

  return (
    <YStack justifyContent="center" alignItems="flex-end" marginRight="$1">
      {!isError ? (
        <>
          <SizableText size="$5" color={color} fontWeight="600" letterSpacing={1}>
            {isLoading && balance?.current === null ? '...' : formatPoints({ points: balance?.current || 0 })}
          </SizableText>
          <SizableText size="$2" color={color} data-dd-privacy="mask">
            {getString('navigation.balance.text')}
          </SizableText>
        </>
      ) : null}
    </YStack>
  )
}

const UserMenuItem = () => {
  const profile = useAppSelector(getProfileData)
  const { isSubscribedToPlan } = useUserSubscriptionStatus()
  const dispatch = useAppDispatch()

  const media = useMedia()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const token = media.gtMobile ? '$5' : media.gtTabletLandscape ? '$7' : '$2'
  const padding = getTokenValue(token, 'space')

  const festiveFlag = useAppSelector((state) => selectFeatureById(state, 'app_web_festive_logo'))

  useEffect(() => {
    dispatch(fetchBalance())
    ;(async () => {
      try {
        const didUpdateAccoount = sessionStorage.getItem('didUpdateAccount')

        if (didUpdateAccoount) {
          const newTokens = await getAuthTokens(true)

          if (newTokens?.accessToken && newTokens?.idToken) {
            await setAuthTokens(newTokens.accessToken, newTokens.idToken)
          }

          dispatch(doFetchProfile())
          sessionStorage.removeItem('didUpdateAccount')
        }
      } catch (err) {
        logger.warn('PrivateAccount: Error fetching new profile: ', err)
      }
    })()
  }, [dispatch])

  return (
    <>
      <Adapt when="gtMobile">
        <Balance />
      </Adapt>
      <Popover stayInFrame={{ padding }} open={isMenuOpen} onOpenChange={(open) => setIsMenuOpen(open)}>
        <Popover.Anchor asChild>
          <NavButton
            aria-label={getString('navigation.menu.button.user.ariaLabel')}
            onPress={() => setIsMenuOpen(!isMenuOpen)}
            icon={
              festiveFlag ? (
                <Icon.SantaWeb strokeWidth={0} accessibilityRole="image" />
              ) : (
                <Icon.Member strokeWidth={0} accessibilityRole="image" />
              )
            }
            iconAfter={
              media.gtMobile ? (
                isMenuOpen ? (
                  <Icon.ChevronUp
                    size="$2"
                    strokeWidth="$0.5"
                    accessibilityRole="image"
                    aria-label={getString('navigation.menu.button.user.open.ariaLabel')}
                  />
                ) : (
                  <Icon.ChevronDown
                    size="$2"
                    strokeWidth="$0.5"
                    accessibilityRole="image"
                    aria-label={getString('navigation.menu.button.user.close.ariaLabel')}
                  />
                )
              ) : null
            }
          />
        </Popover.Anchor>
        <Popover.Content
          role="menu"
          padding={0}
          y={'$-1'}
          elevation="$2"
          enterStyle={{ x: -10, opacity: 0 }}
          exitStyle={{ x: 10, opacity: 0 }}
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          animateOnly={['transform', 'opacity']}>
          <Popover.Arrow />
          <YStack padding="$0.5" minWidth="300px">
            <XStack justifyContent="space-between" alignItems="center" padding="$2">
              <SizableText size="$2" data-dd-privacy="mask">
                {profile?.email}
              </SizableText>
              <Adapt when="mobile">
                <Balance color="$red6" />
              </Adapt>
            </XStack>
            <Separator backgroundColor="$black2" marginBottom="$0.5" marginHorizontal="$-0.5" />
            <MenuLink
              href={regionalizePath(PATHS.MY_ACCOUNT_PERSONAL_DETAILS)}
              aria-label={getString('navigation.menu.link.account.ariaLabel')}>
              {getString('navigation.menu.link.account.text')}
              <Icon.ChevronRight
                size="$2"
                strokeWidth="$0.5"
                $group-MenuLink-hover={{
                  color: '$red6',
                }}
              />
            </MenuLink>
            {profile?.country === ViewingRegion.US && (
              <MenuLink
                href={regionalizePath(PATHS.MY_ACCOUNT_CREDIT_CARD_HUB)}
                aria-label={getString('navigation.menu.link.creditCard.ariaLabel')}>
                {getString('navigation.menu.link.creditCard.text')}
                <Icon.ChevronRight
                  size="$2"
                  strokeWidth="$0.5"
                  $group-MenuLink-hover={{
                    color: '$red6',
                  }}
                />
              </MenuLink>
            )}
            {isSubscribedToPlan && (
              <MenuLink
                href={regionalizePath(PATHS.MY_ACCOUNT_SUBSCRIPTION)}
                aria-label={getString('navigation.menu.link.subscription.ariaLabel')}>
                {getString('navigation.menu.link.subscription.text')}
                <Icon.ChevronRight
                  size="$2"
                  strokeWidth="$0.5"
                  $group-MenuLink-hover={{
                    color: '$red6',
                  }}
                />
              </MenuLink>
            )}
            <MenuLink href={regionalizePath(PATHS.ACTIVITY)} aria-label={getString('navigation.menu.link.activity.ariaLabel')}>
              {getString('navigation.menu.link.activity.text')}
              <Icon.ChevronRight
                size="$2"
                strokeWidth="$0.5"
                $group-MenuLink-hover={{
                  color: '$red6',
                }}
              />
            </MenuLink>
            <MenuLink external href={EXTERNAL_PATHS.BUY_POINTS} aria-label={getString('navigation.menu.link.buyPoints.ariaLabel')}>
              {getString('navigation.menu.link.buyPoints.text')}
              <Icon.ChevronRight
                size="$2"
                strokeWidth="$0.5"
                $group-MenuLink-hover={{
                  color: '$red6',
                }}
              />
            </MenuLink>
            <MenuLink external href={EXTERNAL_PATHS.GIFT_POINTS} aria-label={getString('navigation.menu.link.giftPoints.ariaLabel')}>
              {getString('navigation.menu.link.giftPoints.text')}
              <Icon.ChevronRight
                size="$2"
                strokeWidth="$0.5"
                $group-MenuLink-hover={{
                  color: '$red6',
                }}
              />
            </MenuLink>
            <MenuLink
              external
              href={EXTERNAL_PATHS.TRANSFER_POINTS}
              aria-label={getString('navigation.menu.link.transferPoints.ariaLabel')}>
              {getString('navigation.menu.link.transferPoints.text')}
              <Icon.ChevronRight
                size="$2"
                strokeWidth="$0.5"
                $group-MenuLink-hover={{
                  color: '$red6',
                }}
              />
            </MenuLink>
            <MenuLink href={regionalizePath(PATHS.MY_REWARDS)} aria-label={getString('navigation.menu.link.myRewards.ariaLabel')}>
              {getString('navigation.menu.link.myRewards.text')}
              <Icon.ChevronRight
                size="$2"
                strokeWidth="$0.5"
                $group-MenuLink-hover={{
                  color: '$red6',
                }}
              />
            </MenuLink>
            {profile?.country === ViewingRegion.GB ? (
              <MenuLink
                href={regionalizePath(PATHS.MY_ACCOUNT_REFER_DASHBOARD)}
                aria-label={getString('navigation.menu.link.referAFriend.ariaLabel')}>
                {getString('navigation.menu.link.referAFriend.text')}
                <Icon.ChevronRight
                  size="$2"
                  strokeWidth="$0.5"
                  $group-MenuLink-hover={{
                    color: '$red6',
                  }}
                />
              </MenuLink>
            ) : null}
            <Button
              size="$2"
              justifyContent="flex-start"
              padding="$2"
              borderRadius={'$1'}
              marginTop="$0.5"
              themeInverse
              fontWeight="500"
              onPress={() => dispatch(doLogoutWeb())}>
              {getString('navigation.menu.button.logout.text')}
            </Button>
          </YStack>
        </Popover.Content>
        <Popover.Adapt when="mobile">
          <Popover.Sheet modal dismissOnSnapToBottom snapPointsMode="fit">
            <Popover.Sheet.Overlay opacity={0.5} backgroundColor="$black10" />
            <Popover.Sheet.Handle />
            <Popover.Sheet.Frame paddingBottom="$0.5">
              <Popover.Adapt.Contents />
            </Popover.Sheet.Frame>
          </Popover.Sheet>
        </Popover.Adapt>
      </Popover>
    </>
  )
}

export const Page: FC<PropsWithChildren<PageProps>> = ({
  testId,
  title = 'Virgin red',
  metaDescription,
  children,
  navigationFocusHandler,
  showNavigation = true,
  showRedFooter = true,
  showFooter = true,
  virtualPagePath,
  profileData,
  doUnsetToastMessage,
  isAuthenticated,
}) => {
  const isInitialRender = useRef<boolean>(true)
  const [showSearch, setShowSearch] = useState(false)
  const webSubscriptionFlag = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_SUBSCRIPTION))
  const { isSubscribedToPlan, isLoading: isLoadingUserSubscriptionStatus } = useUserSubscriptionStatus()
  const rewardsNavLinkFlag = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_REWARDS_LINK_EXPERIMENT))

  const dispatch = useAppDispatch()

  const searchRef = useRef<HTMLDivElement>(null)
  const searchButtonRef = useRef<HTMLButtonElement>(null)

  const campaign_id = useCampaignId() // SHOP-387 this has been requested by data team.
  const features = useFeatures()

  useEffect(() => {
    const handleOutsideClick = ({ target }: MouseEvent) => {
      const searchWrapper = searchRef.current
      const searchButton = searchButtonRef.current

      // need to ensure search button is part of the target area
      if (!searchWrapper?.contains(target as Node) && !searchButton?.contains(target as Node)) {
        setShowSearch(false)
      }
    }
    document.addEventListener('click', handleOutsideClick, { capture: true })

    return () => document.removeEventListener('click', handleOutsideClick, { capture: true })
  }, [])

  useEffect(() => {
    document.title = title
    doUnsetToastMessage()
    setDataLayer({
      virtualPageTitle: title,
      loggedIn: isAuthenticated,
      features,
      ...(virtualPagePath && { virtualPagePath }),
      ...(profileData?.memberSupplierIds?.TEALIUM && { tealiumId: profileData?.memberSupplierIds?.TEALIUM }),
      ...(campaign_id && { campaign_id }),
    })

    if (isInitialRender.current) {
      window.scrollTo(0, 0)
      isInitialRender.current = false
    }

    if (navigationFocusHandler) {
      navigationFocusHandler()
    }
  }, [navigationFocusHandler, title, isAuthenticated, virtualPagePath, profileData, campaign_id, doUnsetToastMessage, features])

  return (
    <HelmetProvider>
      <SkipToMainLink />
      <Helmet
        title={title}
        bodyAttributes={{
          'data-testid': testId,
        }}>
        {metaDescription && (
          <meta name="description" content={metaDescription.length >= 160 ? `${metaDescription.slice(0, 156)} ...` : metaDescription} />
        )}
      </Helmet>
      {!isAuthenticated && <SeoHeaders />}
      {showNavigation ? (
        <Nav
          aria-label={getString('navigation.ariaLabel')}
          backgroundColor="$red6"
          flex={1}
          flexDirection="row"
          alignItems="stretch"
          borderBottomColor={'$white'}
          borderBottomWidth={'$0.25'}>
          <NavButtonLink
            href={regionalizePath(PATHS.HOME)}
            aria-label={getString('navigation.home.ariaLabel')}
            icon={<Icon.VirginRed size="$6" />}
          />
          <NavButtonLink href={regionalizePath(PATHS.EARN)} aria-label={getString('navigation.earn.ariaLabel')}>
            {getString('earn.title')}
          </NavButtonLink>
          <NavButtonLink id="spend-nav" href={regionalizePath(PATHS.SPEND)} aria-label={getString('navigation.spend.ariaLabel')}>
            {rewardsNavLinkFlag ? getString('rewards.title') : getString('spend.title')}
          </NavButtonLink>
          {webSubscriptionFlag && !isLoadingUserSubscriptionStatus && !isSubscribedToPlan && (
            <NavButtonLink width="$16" href={regionalizePath(PATHS.SUBSCRIBE)} aria-label={getString('navigation.subscribe.ariaLabel')}>
              {getString('subscribe.title')}
            </NavButtonLink>
          )}
          <XStack
            flex={1}
            testID="sample"
            justifyContent="flex-end"
            marginRight="$2"
            alignItems="center"
            $gtMobile={{
              marginRight: '$4',
            }}
            $gtTabletLandscape={{
              marginRight: '$7',
            }}>
            {isAuthenticated ? (
              <>
                <NavButton
                  aria-label={getString('navigation.searchButton.ariaLabel')}
                  ref={searchButtonRef}
                  aria-expanded={showSearch}
                  onPress={() => setShowSearch(!showSearch)}
                  zIndex="$1"
                  cursor="pointer"
                  marginRight="$1"
                  pointerEvents="auto"
                  variant={showSearch ? 'secondary' : undefined}
                  icon={
                    showSearch ? (
                      <Icon.X aria-label={getString('navigation.searchIconClose.ariaLabel')} accessibilityRole="image" />
                    ) : (
                      <Icon.Search
                        strokeWidth={0}
                        aria-label={getString('navigation.searchIconOpen.ariaLabel')}
                        accessibilityRole="image"
                      />
                    )
                  }
                />
                <UserMenuItem />
              </>
            ) : (
              <Button
                aria-label={getString('navigation.loginButton.title')}
                size="$5"
                $gtMobile={{
                  width: '$17',
                }}
                themeInverse
                onPress={() => dispatch(doLoginWeb(getPath() ?? undefined))}
                variant="secondary"
                width="$14"
                fontSize="$3"
                fontWeight="500">
                {getString('navigation.loginButton.title')}
              </Button>
            )}
          </XStack>
        </Nav>
      ) : null}
      {!isAuthenticated && <RegionSelectorBanner />}
      {showSearch && <Search ref={searchRef} />}
      <main id="main-content">{children}</main>
      <footer>
        {showRedFooter && <RedFooter />}
        {showFooter && <Footer />}
      </footer>
    </HelmetProvider>
  )
}
