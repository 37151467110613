import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, Input, Text, View } from '@red-ui/components'
import { Airport, searchAirports } from '../../api/searchAirports'
import { CloseRight } from '../SideSheet/Header'
import { useDebounce } from '@/redux/hooks/hooks'
import { getString } from '@vrw/data'

type Props = {
  placeholder: string
  info: string
  onChangeResults: (results: Airport[]) => void
}

export const SearchInput = ({ placeholder, info, onChangeResults }: Props) => {
  const inputRef = useRef<Input>(null)
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const onChange = (text: string) => {
    setValue(text)
  }

  useEffect(() => {
    const results = searchAirports(debouncedValue)
    onChangeResults(results)
  }, [debouncedValue, onChangeResults])

  const onClearInput = () => {
    inputRef.current?.focus()
    onChange('')
    onChangeResults([])
  }

  return (
    <>
      <View>
        <Input
          aria-label={getString('redSkySearch.searchInput.arialLabel')}
          value={value}
          onChangeText={onChange}
          ref={inputRef}
          height="$9"
          placeholder={placeholder}
        />
        <AnimatePresence>
          {value && (
            <CloseRight
              aria-label={getString('redSkySearch.searchInput.clearButton.arialLabel')}
              animation="quick"
              enterStyle={{ opacity: 0 }}
              exitStyle={{ opacity: 0 }}
              top="$3"
              right="$2"
              onPress={onClearInput}
            />
          )}
        </AnimatePresence>
      </View>
      <Text marginLeft="$3" marginTop="$0.5">
        {info}
      </Text>
    </>
  )
}
