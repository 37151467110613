import React from 'react'
import { XStack, Text } from '@red-ui/components'
import { formatPoints } from '@vrw/data/src/utils/formatters'
import { getString } from '@vrw/data'

type Props = {
  label: string
  points: number
}

export const FlightPointsPrice = ({ label, points }: Props) => {
  return (
    <XStack justifyContent="space-between" alignItems="center">
      <Text fontSize="$2">{label}</Text>
      <Text fontWeight="700" fontSize="$8">
        {`${formatPoints({ points })} `}
        <Text>{getString('redSkySearch.results.card.points')}</Text>
      </Text>
    </XStack>
  )
}
