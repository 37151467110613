import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor, Spacing } from '../../../../style/variables'
import { media } from '../../../../style/media'
import { CommonSpacingStyles, CommonTextStyles } from '../../CommonStyles'

interface H1HeaderProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
  letterSpacing?: number
  lineHeight?: number
}

const H1Header: FC<H1HeaderProps> = ({
  children,
  fontFamily,
  textAlign,
  color,
  marginBottom,
  marginTop,
  textTransform,
  weight,
  fontStyle,
  fontSize,
  letterSpacing,
  lineHeight,
}) => {
  const defaultMargin: Spacing = {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  }
  const setMarginTop: Spacing = {
    ...defaultMargin,
    ...marginTop,
  }
  const setMarginBottom: Spacing = {
    ...defaultMargin,
    ...marginBottom,
  }
  const horizontalMargin = textAlign === 'left' || textAlign === 'right' ? '0' : 'auto'
  const margin = {
    mobile: `${setMarginTop.mobile}px ${horizontalMargin} ${setMarginBottom.mobile}px`,
    tablet: `${setMarginTop.tablet}px ${horizontalMargin} ${setMarginBottom.tablet}px`,
    desktop: `${setMarginTop.desktop}px ${horizontalMargin} ${setMarginBottom.desktop}px`,
  }
  return (
    <>
      <style jsx>{`
        .header-one {
          font-family: ${fontFamily ?? FontFamilies.barlow};
          font-size: ${fontSize?.mobile ? fontSize.mobile : 28}px;
          font-weight: ${weight ? weight : 'bold'};
          font-style: ${fontStyle ? fontStyle : 'italic'};
          font-stretch: normal;
          letter-spacing: ${letterSpacing ? letterSpacing : ' -0.28'}px;
          line-height: ${lineHeight ? lineHeight : '1.25'};
          color: ${color ? color : globalColor.white};
          text-align: ${textAlign ? textAlign : 'inherit'};
          margin: ${margin.mobile};
          text-transform: ${textTransform ?? 'inherit'};
        }
        @media ${media.tabletAndHigher} {
          .header-one {
            font-size: ${fontSize?.tablet ? fontSize.tablet : 40}px;
            margin: ${margin.tablet};
            line-height: 1.2;
            letter-spacing: -0.4px;
          }
        }
        @media ${media.desktopAndHigher} {
          .header-one {
            font-size: ${fontSize?.desktop ? fontSize.desktop : 56}px;
            line-height: 1.14;
            letter-spacing: -0.56px;
            margin: ${margin.desktop};
          }
        }
      `}</style>
      <h1 className="header-one">{children}</h1>
    </>
  )
}

export default H1Header
