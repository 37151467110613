import React, { FC } from 'react'
import { View } from '@red-ui/components'

interface SpacerProps {
  size: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
}

const sizes = {
  xSmall: {
    mobile: '12px',
    tablet: '12px',
    desktop: '12px',
  },
  small: {
    mobile: '$1',
    tablet: '$2',
    desktop: '$3',
  },
  medium: {
    mobile: '$2',
    tablet: '$4',
    desktop: '$5',
  },
  large: {
    mobile: '$5',
    tablet: '$5',
    desktop: '$8',
  },
  xLarge: {
    mobile: '$5',
    tablet: '$8',
    desktop: '$11',
  },
}

const Spacer: FC<SpacerProps> = ({ size }) => {
  const { tablet, mobile, desktop } = sizes[size]

  return (
    <View
      $gtTabletLandscape={{ marginBottom: desktop }}
      $gtMobile={{ marginBottom: tablet }}
      $mobile={{ marginBottom: mobile }}
      className="spacer"
    />
  )
}

export { Spacer }
