export const EVIDENTLY_PROJECT = 'red-app'

export type FeatureValue = string | number | boolean

export enum FeatureName {
  WEB_SUBSCRIPTION = 'web_subscription',
  WEB_REDSKY_SEARCH = 'web_redsky_search',
  APP_WEB_CONTENTFUL_PREVIEW = 'app_web_contentful_preview',
  WEB_REWARDS_LINK_EXPERIMENT = 'web_rewards_link_experiment',
  CC_CTA_EXPERIMENT = 'web_cc_cta_experiment',
  GUEST_APPLY_MVP = 'web_guest_apply_mvp',
  GUEST_APPLY_MODAL = 'web_guest_apply_modal',
}

export interface Feature {
  entityId: string
  value: {
    [key in FeatureValueType]: FeatureValue
  }
  variation: string
  name: FeatureName
}

export interface FeaturesResponse {
  features: Feature[]
}

export interface FeaturesState {
  features: Feature[]
  errorMessage: string
  isLoading: boolean
}

export enum FeatureValueType {
  BOOLEAN_VALUE = 'boolValue',
  STRING_VALUE = 'stringValue',
  DOUBLE_VALUE = 'doubleValue',
  LONG_VALUE = 'longValue',
}
