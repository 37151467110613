import React from 'react'
import { getString } from '@vrw/data'
import { useUserSubscriptionStatus, useSubscriptionPlanById } from '../../../query/subscriptions'
import { formatPoints } from '@vrw/data/src/utils/formatters'

type InventoryCardPointsProps = {
  subscriptionPlanId?: string
  cost?: number
  showPlus?: boolean
  earnPointsText?: string
}

export const InventoryCardPoints = ({ subscriptionPlanId, cost, showPlus, earnPointsText }: InventoryCardPointsProps) => {
  const { isSubscribedToPlan } = useUserSubscriptionStatus()
  const { data: subscriptionPlan } = useSubscriptionPlanById(subscriptionPlanId)
  const subscriptionPlanName = subscriptionPlan?.name

  if (!!subscriptionPlanId && !isSubscribedToPlan && subscriptionPlanName) {
    return <span>{getString('subscriptionCarouselTile.points.unsubscribed').replace('{{planName}}', subscriptionPlanName)}</span>
  }

  if (cost) {
    return (
      <>
        {formatPoints({ points: cost, showPlus })}
        <span> point{cost !== 1 ? 's' : ''}</span>
      </>
    )
  }

  if (earnPointsText) {
    return <span>{earnPointsText}</span>
  }

  return null
}
