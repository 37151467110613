import { getRedDataApiPaths } from '../config'
import { post } from '../utils/httpClient'

const getBaseUrl = (isGuest: boolean) => getRedDataApiPaths().GENERATE_B2C_API(`/v1/card-applications${isGuest ? '/guest' : ''}`)

export const apiCreateApplication = async ({
  isAuthenticated,
  accorId,
  siteCode,
}: {
  isAuthenticated: boolean
  accorId?: string
  siteCode?: string
}) => {
  const response = await (post(getBaseUrl(!isAuthenticated), isAuthenticated, false, { accorId, siteCode }) as Promise<
    | {
        message?: string
        token?: string
      }
    | undefined
  >)

  if (!response?.token) throw new Error('Failed to create application missing token')

  return response
}
