import React, { FC } from 'react'

import { color } from '@/style/variables'
import { Picture } from '@/components'

import { useMedia, View } from '@red-ui/components'

import { brandLogoFallBackImage, brandLogoImages, optimizeContentfulImageUrl } from '../../../helpers/images'
import { EarnType } from '@vrw/data/src/redux/rewardsEarn/types'
import { layout } from '@virgin-core/styles'

type ResponsiveImages = {
  mobile: {
    imgSrc: string
    hiResImgSrc: string
  }
  tablet: {
    imgSrc: string
    hiResImgSrc: string
  }
}

type RenderRewardsImageProps = {
  collinsonLogoFeature: boolean
  brandLogoUrl: string
  isEarn: boolean
  rewardImageAlt: string
  responsiveHeroImages: ResponsiveImages
}

const renderRewardsImage = ({
  collinsonLogoFeature,
  brandLogoUrl,
  isEarn,
  rewardImageAlt,
  responsiveHeroImages,
}: RenderRewardsImageProps) => {
  if (collinsonLogoFeature) {
    const brandImages = {
      mobile: {
        imgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 269, height: 269 }, 50),
        hiResImgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 538, height: 538 }, 50),
      },
      tablet: {
        imgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 361, height: 361 }, 50),
        hiResImgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 722, height: 722 }, 50),
      },
      desktop: {
        imgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 436, height: 436 }, 50),
        hiResImgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 872, height: 872 }, 50),
      },
    }
    return (
      <>
        <style jsx>{`
          .collinson-reward-image-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
          }
          .collinson-reward-image {
            width: 50%;
            height: 50%;
          }
        `}</style>
        <div data-testid="collinson-reward-image" className="collinson-reward-image-container">
          <div className="collinson-reward-image">
            <Picture
              altText={rewardImageAlt.toString()}
              width={960}
              fallbackImg={brandImages.tablet.imgSrc}
              height={isEarn ? 720 : 540}
              responsiveImages={brandImages}
              loading={'eager'}
              isWebP={true}
            />
          </div>
        </div>
      </>
    )
  }
  return (
    <Picture
      altText={rewardImageAlt.toString()}
      width={960}
      fallbackImg={responsiveHeroImages.tablet.imgSrc}
      height={isEarn ? 720 : 540}
      responsiveImages={responsiveHeroImages}
      loading={'eager'}
      isWebP={true}
      className="reward-image"
    />
  )
}

export type RewardImageProps = {
  rewardImageUrl: string
  rewardImageAlt: string
  brandLogoUrl: string | undefined
  brandLogoAlt: string
  isEarn?: boolean
  earnType?: EarnType
}

const RewardImage: FC<RewardImageProps> = ({ rewardImageUrl, rewardImageAlt, brandLogoUrl, brandLogoAlt, isEarn, earnType }) => {
  const collinsonLogoFeature = earnType === EarnType.COLLINSON
  const media = useMedia()

  let imageHeight

  if (media.mobile || media.tabletPortrait) {
    imageHeight = isEarn ? 555 : 416
  } else {
    imageHeight = isEarn ? 650 : 540
  }

  const responsiveHeroImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 740, height: imageHeight }, 50),
      hiResImgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 1480, height: imageHeight * 2 }, 50),
    },
    tablet: {
      imgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 740, height: imageHeight }, 50),
      hiResImgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 1920, height: imageHeight * 2 }, 60),
    },
  }

  return (
    <View
      style={{ margin: '0 auto', position: 'relative' }}
      width={'100%'}
      $gtTabletPortrait={{ width: layout.heroWidth.tablet }}
      $gtTabletLandscape={{ width: layout.heroWidth.desktop }}>
      <View backgroundColor={'$black2'} $gtTabletPortrait={{ borderRadius: '$4', overflow: 'hidden' }}>
        {renderRewardsImage({
          collinsonLogoFeature,
          brandLogoUrl: brandLogoUrl || '',
          isEarn: !!isEarn,
          rewardImageAlt,
          responsiveHeroImages,
        })}
      </View>
      {brandLogoUrl && brandLogoAlt && (
        <View
          width={'$10'}
          height={'$10'}
          bottom={-32}
          style={{
            backgroundColor: color.white,
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 16px 0px',
            left: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
            zIndex: 1,
            right: 0,
          }}
          $gtMobile={{
            bottom: -48,
            height: '$12',
            width: '$12',
          }}
          $gtTabletPortrait={{
            bottom: -64,
            height: '$16',
            width: '$16',
          }}>
          <Picture
            altText={`${brandLogoAlt} logo`}
            width={144}
            height={144}
            fallbackImg={brandLogoFallBackImage(brandLogoUrl)}
            responsiveImages={brandLogoImages(brandLogoUrl)}
            loading={'lazy'}
            isWebP={true}
          />
        </View>
      )}
    </View>
  )
}

export { RewardImage }
