/*
 * PRE-EXISTING VARIABLES FOR RESPONSIVE MEDIA
 * SEE './responsive' FOR NEW VARIABLES
 */

const mobileMin = 375
const mobileLargeMin = 420
const tabletMin = 768
const desktopMin = 1024
const hiResDesktopMin = 1440

const mobileSmallMax = mobileMin - 1
const mobileMax = tabletMin - 1
const mobileLargeMax = mobileLargeMin - 1
const tabletMax = desktopMin - 1
const desktopMax = hiResDesktopMin - 1

export const size = {
  mobileMin,
  mobileMax,
  tabletMin,
  tabletMax,
  desktopMin,
  hiResDesktopMin,
} as const

export const media = {
  mobileSmallAndLower: `(max-width: ${mobileSmallMax}px)`,
  mobileLargeAndLower: `(max-width: ${mobileLargeMin - 1}px)`,
  mobileAndLower: `(max-width: ${mobileMax}px)`,
  tablet: `(min-width: ${tabletMin}px) and (max-width: ${tabletMax}px)`,
  desktop: `(min-width: ${desktopMin}px) and (max-width: ${desktopMax}px)`,
  desktopAndHigher: `(min-width: ${desktopMin}px)`,
  hiResDesktop: `(min-width: ${hiResDesktopMin}px)`,
  mobileLargeAndHigher: `(min-width: ${mobileLargeMax}px)`,
  tabletAndHigher: `(min-width: ${tabletMin}px)`,
  tabletAndLower: `(max-width: ${tabletMax}px)`,
  retinaDisplay: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
} as const
