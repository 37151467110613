import React, { useState } from 'react'
import { View } from '@red-ui/components'
import { Header } from '../SideSheet/Header'
import { SearchInput } from './SearchInput'
import { Results } from './Results'
import { Airport } from '../../api/searchAirports'

type Props = {
  onClose: () => void
  title: string
  info: string
  onSelect: () => void
}

export const AutocompleteForm = ({ onClose, onSelect, title, info }: Props) => {
  const [results, setResults] = useState<Airport[]>([])

  return (
    <View flex={1}>
      <Header iconPosition="left" title={title} onClose={onClose} />
      <View flex={1} paddingHorizontal="$3" paddingVertical="$5">
        <View paddingBottom="$3">
          <SearchInput onChangeResults={setResults} placeholder={title} info={info} />
        </View>
        <Results onSelect={onSelect} results={results} />
      </View>
    </View>
  )
}
