import React from 'react'
import { Accordion, Icon, Square, Text, XStack } from '@red-ui/components'
import { getString } from '@vrw/data'

export type Props = {
  value: string
  children: React.ReactNode
  isActive?: boolean
}

export const Question = ({ value, children, isActive = false }: Props) => {
  const title = getString(`redSkySearch.question.${value}.title`)

  const renderTriggerContent = ({ open }: { open: boolean }) => (
    <XStack flex={1} alignItems="center" justifyContent="space-between">
      <Text fontSize="$5" fontWeight="600">
        {title}
      </Text>
      <Square animation="200ms" rotate={open ? '180deg' : '0deg'}>
        <Icon.ChevronDown size="$4" />
      </Square>
    </XStack>
  )

  return (
    <Accordion.Item
      value={value}
      borderWidth={1}
      borderColor={isActive ? '$black10' : '$black2'}
      borderRadius="$5"
      padding="$2"
      backgroundColor="$white">
      <Accordion.Header>
        <Accordion.Trigger
          backgroundColor="$white"
          unstyled
          width="100%"
          alignItems="center"
          hoverStyle={{ backgroundColor: '$white' }}
          focusStyle={{ backgroundColor: '$white' }}>
          {renderTriggerContent}
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.HeightAnimator animation="200ms">
        <Accordion.Content unstyled animation="200ms" enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} backgroundColor="$white">
          {children}
        </Accordion.Content>
      </Accordion.HeightAnimator>
    </Accordion.Item>
  )
}
