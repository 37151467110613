import React from 'react'
import { getString } from '@vrw/data'
import { H3, Icon, Paragraph, styled, XStack, YStack, Anchor, TextStyle } from '@red-ui/components'

const Container = styled(XStack, {
  marginTop: '$5',
})

const Wrapper = styled(YStack, {
  paddingVertical: '$5',
  paddingHorizontal: '$3',
  alignItems: 'center',
  flex: 1,
})

const textProps: TextStyle = {
  maxWidth: 300,
  fontSize: '$4',
  $gtMobile: {
    fontSize: '$5',
    maxWidth: 330,
  },
  $gtTabletLandscape: {
    fontSize: '$true',
    maxWidth: 370,
  },
}

const StyledAnchor = styled(Anchor, {
  variant: 'red',
  fontWeight: 700,
  marginTop: '$1',
})

const StyledHeading = styled(H3, {
  marginVertical: '$2',
  fontWeight: 600,
  fontSize: '$5',
  $gtMobile: { fontSize: '$7' },
  $gtDesktop: { fontSize: '$8' },
})

export const HelpCTA = () => (
  <Container>
    <Wrapper>
      <Icon.Buoy color="$red6" size="$7" $gtMobile={{ scale: 1 }} $gtTabletLandscape={{ scale: 1.3 }} />
      <StyledHeading>{getString('helpCTA.title')}</StyledHeading>
      <Paragraph textAlign="center" {...textProps}>
        {getString('helpCTA.text')}
      </Paragraph>
      <StyledAnchor
        paddingInline={'$1'}
        focusStyle={{ outlineColor: '$black10' }}
        href={getString('link.CTA.help')}
        target="_blank"
        rel="noopener noreferrer"
        {...textProps}>
        {getString('helpCTA.linkText')}
      </StyledAnchor>
    </Wrapper>
  </Container>
)
