import React from 'react'
import { Button, ButtonProps, Icon, Text, XStack } from '@red-ui/components'

type Props = {
  onClose: () => void
  title: string
  iconPosition?: 'left' | 'right'
}

const defaultProps: ButtonProps = {
  backgroundColor: 'transparent',
  unstyled: true,
  borderStyle: 'unset',
  borderWidth: '$0',
  position: 'absolute',
  width: '$3',
  height: '$3',
  padding: '$0',
  borderRadius: '$6',
  alignItems: 'center',
  justifyContent: 'center',
  hoverStyle: {
    backgroundColor: '$black2',
    cursor: 'pointer',
  },
  pressStyle: {
    backgroundColor: '$black4',
  },
}

export const CloseLeft = (props: ButtonProps) => {
  return <Button {...defaultProps} left="$3" icon={<Icon.ArrowLeft size="$3" color="$black10" />} {...props} />
}

export const CloseRight = (props: ButtonProps) => {
  return <Button {...defaultProps} right="$3" icon={<Icon.XCircle size="$3" color="$black5" />} {...props} />
}

export const Header = ({ title, onClose, iconPosition = 'right' }: Props) => {
  return (
    <XStack
      paddingVertical="$3"
      justifyContent="center"
      alignItems="center"
      paddingBottom="$3"
      borderBottomWidth={1}
      borderColor="$black2"
      width="100%">
      {iconPosition === 'left' && <CloseLeft onPress={onClose} />}
      <Text textAlign="center" fontSize="$6" fontWeight="600">
        {title}
      </Text>
      {iconPosition === 'right' && <CloseRight onPress={onClose} />}
    </XStack>
  )
}
