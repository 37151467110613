import React, { useState } from 'react'
import { Accordion, ScrollView, Text, View } from '@red-ui/components'
import { Question } from './Question'
import { Footer } from './Footer'
import { getString } from '@vrw/data'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'
import { PATHS } from '@/router/paths'
import { ListItem } from './ListItem'
import { Header } from '../SideSheet/Header'

export const questions = [{ value: 'departure' }, { value: 'destination' }, { value: 'date' }, { value: 'nature' }]

type Props = {
  onClose: () => void
  onFromInputOpen: () => void
  onToInputOpen: () => void
}

export const QuestionForm = ({ onClose, onFromInputOpen, onToInputOpen }: Props) => {
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)
  const isBackVisible = currentQuestionIdx !== 0
  const currentQuestion = questions[currentQuestionIdx]
  const redNavigate = useRedNavigate()

  const onContinue = () => {
    if (currentQuestionIdx >= questions.length - 1) {
      redNavigate(PATHS.REDSKY_SEARCH_RESULTS)
    }
    setCurrentQuestionIdx((prev) => Math.min(prev + 1, questions.length - 1))
  }

  const onBack = () => {
    setCurrentQuestionIdx((prev) => Math.max(prev - 1, 0))
  }

  const renderQuestion = (question: any) => {
    const { value } = question

    const componentMap: Record<string, JSX.Element | null> = {
      departure: <ListItem onPress={onFromInputOpen} label={getString('redSkySearch.question.departure.label')} />,
      destination: <ListItem onPress={onToInputOpen} label={getString('redSkySearch.question.destination.label')} />,
    }

    return (
      <Question key={value} isActive={currentQuestion.value === value} {...question}>
        <View borderTopWidth={1} borderTopColor="$black2" paddingTop="$2" marginTop="$2">
          <Text>{getString(`redSkySearch.question.${value}.info`)}</Text>
          {componentMap[value]}
        </View>
      </Question>
    )
  }

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', gap: '$3' }}>
      <View>
        <Header title={getString('redSkySearch.title')} onClose={onClose} />
        <View paddingHorizontal="$3" paddingVertical="$5">
          <Accordion type="single" collapsible gap="$2" value={currentQuestion.value}>
            {questions.map(renderQuestion)}
          </Accordion>
        </View>
      </View>
      <View padding="$3">
        <Footer onContinue={onContinue} onBack={onBack} isBackVisible={isBackVisible} />
      </View>
    </ScrollView>
  )
}
