import React from 'react'
import { XStack, Text, Icon, Anchor } from '@red-ui/components'
import { getString } from '@vrw/data'

export const SeatTypeInfo = () => {
  return (
    <>
      <Text fontWeight="600" marginBottom="$3">
        Economy Classic
      </Text>
      <XStack marginBottom="$2" alignItems="center">
        <Icon.Armchair />
        <Text fontSize="$3" marginLeft="$2">
          Standard Seat
        </Text>
      </XStack>
      <XStack marginBottom="$2" alignItems="center">
        <Icon.BaggageClaim />
        <Text fontSize="$3" marginLeft="$2">
          1 x 23kg checked bag
        </Text>
      </XStack>
      <XStack marginBottom="$2" alignItems="center">
        <Icon.Backpack />
        <Text fontSize="$3" marginLeft="$2">
          1 x 10kg carry on bag
        </Text>
      </XStack>
      <Anchor color="$red6" fontSize="$3" cursor="pointer">
        {getString('redSkySearch.results.card.seat.info.more')}
      </Anchor>
    </>
  )
}
