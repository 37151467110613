import { ViewingRegion } from '@vrw/data/src/utils'
import { generatePath } from 'react-router-dom'
import { regionalizePath, getLocale, localeToRegion } from './viewingRegionWeb'

// Please use useRedNavigate for navigations
export const navigateBack = () => window.history.back()

/**
 * This gets passed to the data package because it does not require React Router context
 * In any other scenario, please use useRedNavigate()
 */
export const manuallyNavigate = (routeName: string, params?: { [key: string]: any }, region?: ViewingRegion) => {
  // Check if this is a full URL
  const isExternal = /^https?:\/\//.test(routeName)
  if (isExternal) {
    window.location.href = routeName
    return
  }

  const currentRegion = localeToRegion(getLocale())
  const target = generatePath(regionalizePath(routeName, region ?? currentRegion), params)

  const current = window.location.pathname
  // Preserve VAA token in URL hash when redirecting from /onboarding/link-vaa to onboarding/welcome
  const hash = target.includes('onboarding/welcome') && window.location.hash
  const completeTarget = hash ? `${target}${hash}` : target
  if (target !== current) {
    window.history.pushState({}, '', completeTarget)
    // Dispatch a popstate event to notify the router about the route change
    window.dispatchEvent(new PopStateEvent('popstate'))
  }
}
