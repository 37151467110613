import React, { FC, ReactNode } from 'react'
import { spacing } from '@/style/variables'
import { media, size } from '@/style/media'

type ContentProps = {
  children: ReactNode
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

/**
 * Content wrapper component which adds responsive margins.
 *
 * @param children the content to render with default page margins.
 * @constructor
 */
const Content: FC<ContentProps> = ({ children, ...rest }) => (
  <>
    <style jsx>{`
      .content {
        margin: 0 ${spacing.pageMargins.mobile}px;
      }

      @media ${media.tablet} {
        .content {
          margin: 0 ${spacing.pageMargins.tablet}px;
        }
      }

      @media ${media.desktop} {
        .content {
          margin: 0 ${spacing.pageMargins.desktop}px;
        }
      }
      @media ${media.hiResDesktop} {
        .content {
          width: ${size.hiResDesktopMin - 2 * spacing.pageMargins.hiRes}px;
          margin: auto;
        }
      }
    `}</style>
    <div className="content" {...rest}>
      {children}
    </div>
  </>
)

export { Content }
