import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Page, CustomLink } from '../../components'
import { virginRedPath } from '../../router/paths'
import { XStack, Text, Icon, Spinner } from '@red-ui/components/'
import { getString } from '@vrw/data'
import {
  PageWrapper,
  BlockWrapper,
  TextSectionWrapper,
  ImageSectionWrapper,
  HeaderText,
  HeroImage,
  SubtitleText,
  ConfirmButton,
} from './styles'
import { useConfirmSubscription } from './useConfirmSubscription'
import { ErrorPage404 } from '../ErrorPage404/ErrorPage404'

export const SubscriptionConfirmation = () => {
  const navigate = useNavigate()
  const { sessionId, error, isLoading, plan } = useConfirmSubscription()

  if (!sessionId) {
    return <ErrorPage404 />
  }

  const renderPageContent = () => {
    if (isLoading) {
      return <Spinner size="large" color="$red6" />
    }

    if (error) {
      return (
        <TextSectionWrapper>
          <HeaderText>{getString('subscriptionConfirmation.page.error.header')}</HeaderText>
          <SubtitleText>{getString('subscriptionConfirmation.page.error.subtitle')}</SubtitleText>
          <ConfirmButton onPress={() => navigate(-1)}>{getString('subscriptionConfirmation.page.error.confirmButtonText')}</ConfirmButton>
        </TextSectionWrapper>
      )
    }

    if (plan) {
      return (
        <>
          <TextSectionWrapper>
            <HeaderText>
              {getString('subscriptionConfirmation.page.header.firstPart')}
              <HeaderText color={plan.primaryColor}>{plan.name}</HeaderText>
              {getString('subscriptionConfirmation.page.header.secondPart')}
            </HeaderText>
            <SubtitleText>{getString('subscriptionConfirmation.page.subtitle')}</SubtitleText>
            {plan.features.map((feature) => (
              <XStack key={feature.description} marginBottom="$2">
                <Icon.Check marginRight="$2" />
                <Text>{feature.description}</Text>
              </XStack>
            ))}
            <CustomLink to={virginRedPath}>
              <ConfirmButton>{getString('subscriptionConfirmation.page.confirmButtonText')}</ConfirmButton>
            </CustomLink>
          </TextSectionWrapper>
          <ImageSectionWrapper>
            <HeroImage />
          </ImageSectionWrapper>
        </>
      )
    }

    return null
  }

  return (
    <Page title={getString('subscriptionConfirmation.page.title')}>
      <PageWrapper>
        <BlockWrapper>{renderPageContent()} </BlockWrapper>
      </PageWrapper>
    </Page>
  )
}
