import React, { FC } from 'react'

import { formatDateTimeFull } from '@vrw/data/src/utils/formatters'
import { Icon, Text, View } from '@red-ui/components'

interface DateAndLocationProps {
  location: string
  dateAndTime: string
}

const DateAndLocation: FC<DateAndLocationProps> = ({ location, dateAndTime }) => (
  <>
    {!!location && (
      <View marginBottom="$1" display="flex" alignItems="center" flexDirection="row" justifyContent="center" data-testid="reward-location">
        <Icon.MapPin size={'$3'} color="$black10" $gtMobile={{ scale: 1.2 }} $gtTabletLandscape={{ scale: 1.3 }} />
        <Text marginLeft={'$1'} fontSize={'$6'}>
          {location}
        </Text>
      </View>
    )}
    {!!dateAndTime && (
      <View marginBottom="$1" display="flex" alignItems="center" flexDirection="row" justifyContent="center" data-testid="reward-date-time">
        <Icon.Calendar size={'$3'} color="$black10" />
        <Text marginLeft={'$1'} fontSize={'$6'}>
          {formatDateTimeFull(dateAndTime, true)}
        </Text>
      </View>
    )}
  </>
)

export { DateAndLocation }
