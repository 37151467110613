import React, { useEffect } from 'react'
import { Paragraph, XStack, YStack, Button, AccountCard } from '@red-ui/components'
import { getString } from '@vrw/data'
import { LinkingJourneyOrigins } from '@vrw/data/src/utils'
import { getIsUpdating } from '@vrw/data/src/redux/accounts/selectors'
import { doLinkAccountsWeb, doLinkAccountsWebResponseHandler } from '@vrw/data/src/redux/accounts/dispatchers'
import { Page, Spinner } from '../../../components'
import { MyAccountBackground } from '../MyAccountBackground'
import { PUBLIC_URL } from '../../../config'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/hooks'
import { useAccounts } from '../../../query/accounts'

const LinkAccounts = () => {
  const isUpdating = useAppSelector(getIsUpdating)
  const { data: accounts, isLoading, refetch } = useAccounts()
  const vaaAccount = accounts?.find((account) => account.partnerCode === 'VAA')
  const syfAccount = accounts?.find((account) => account.partnerCode === 'SYF')
  const dispatch = useAppDispatch()

  useEffect(() => {
    const asyncFunc = async () => {
      if (isUpdating) {
        const response = await dispatch(doLinkAccountsWebResponseHandler())
        return response.done && refetch()
      }
      return undefined
    }
    asyncFunc()
  }, [dispatch, isUpdating, refetch])

  return (
    <Page title="Your linked accounts | Virgin Red" testId="page-account-link-accounts">
      <MyAccountBackground>
        <XStack alignItems="flex-start" gap={'$4'} $tabletLandscape={{ flexDirection: 'column' }}>
          {syfAccount && (
            <YStack>
              <AccountCard
                isCardLinked={!!syfAccount}
                backgroundUri={`${PUBLIC_URL || ''}/img/link-syf-account/card-background.png`}
                logoUrl={`${PUBLIC_URL || ''}/img/link-syf-account/logo-linked.png`}
                partnerName={getString('account.linkAccounts.syf.partnerName')}
                message={getString('account.linkAccounts.vaa.status.linked')}
                hideFooter
                width={345}
                margin={0}
              />
            </YStack>
          )}
          <YStack alignItems="center">
            <AccountCard
              isCardLinked={!!vaaAccount}
              backgroundUri={`${PUBLIC_URL || ''}/img/link-vaa-account/card-background.jpg`}
              logoUrl={`${PUBLIC_URL || ''}/img/link-vaa-account/vaa-logo.png`}
              partnerName={getString('account.linkAccounts.vaa.partnerName')}
              message={getString(vaaAccount ? 'account.linkAccounts.vaa.status.linked' : 'account.linkAccounts.vaa.status.unlinked')}
              partnerUserIdLabel={getString('account.linkAccounts.vaa.partnerUserIdName')}
              partnerUserId={vaaAccount?.partnerUserId}
              buttonComponent={
                <Button
                  testID="button-link-vaa-account"
                  size="$5"
                  fontWeight="700"
                  height="$6"
                  width="100%"
                  paddingHorizontal="$0"
                  onPress={() => dispatch(doLinkAccountsWeb(LinkingJourneyOrigins.ACCOUNT))}>
                  {isUpdating || isLoading ? <Spinner size="small" color="white" /> : getString('account.linkAccounts.vaa.button')}
                </Button>
              }
              width={345}
              margin={0}
            />
            {!vaaAccount && (
              <YStack width={345} style={{ textAlign: 'center' }}>
                <Paragraph size={'$4'} fontWeight={'700'} marginVertical={'$3'}>
                  {getString('account.linkAccounts.pending.title')}
                </Paragraph>
                <Paragraph size={'$4'}>{getString('account.linkAccounts.pending.body')}</Paragraph>
              </YStack>
            )}
          </YStack>
        </XStack>
      </MyAccountBackground>
    </Page>
  )
}

export { LinkAccounts }
