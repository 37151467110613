export type Airport = {
  code: string
  airportName: string
  cityName: string
  countryName: string
}

export const airports: Airport[] = [
  { code: 'LHR', airportName: 'London Heathrow (LHR)', cityName: 'London', countryName: 'United Kingdom' },
  { code: 'EDI', airportName: 'Edinburgh (EDI)', cityName: 'Edinburgh', countryName: 'United Kingdom' },
  { code: 'MAN', airportName: 'Manchester (MAN)', cityName: 'Manchester', countryName: 'United Kingdom' },
  { code: 'ACC', airportName: 'Accra (ACC)', cityName: 'Accra', countryName: 'Ghana' },
  { code: 'CPT', airportName: 'Cape Town (CPT)', cityName: 'Cape Town', countryName: 'South Africa' },
  { code: 'JNB', airportName: 'Johannesburg (JNB)', cityName: 'Johannesburg', countryName: 'South Africa' },
  { code: 'LOS', airportName: 'Lagos (LOS)', cityName: 'Lagos', countryName: 'Nigeria' },
  { code: 'BLR', airportName: 'Bengaluru (BLR)', cityName: 'Bengaluru', countryName: 'India' },
  { code: 'DEL', airportName: 'Delhi (DEL)', cityName: 'Delhi', countryName: 'India' },
  { code: 'MLE', airportName: 'Maldives (MLE)', cityName: 'Maldives', countryName: 'Maldives' },
  { code: 'BOM', airportName: 'Mumbai (BOM)', cityName: 'Mumbai', countryName: 'India' },
  { code: 'YYZ', airportName: 'Toronto (YYZ)', cityName: 'Toronto', countryName: 'Canada' },
  { code: 'ANU', airportName: 'Antigua (ANU)', cityName: 'Antigua', countryName: 'Antigua and Barbuda' },
  { code: 'BGI', airportName: 'Barbados (BGI)', cityName: 'Barbados', countryName: 'Barbados' },
  { code: 'GND', airportName: 'Grenada (GND)', cityName: 'Grenada', countryName: 'Grenada' },
  { code: 'MBJ', airportName: 'Montego Bay (MBJ)', cityName: 'Montego Bay', countryName: 'Jamaica' },
  { code: 'NAS', airportName: 'Bahamas (NAS)', cityName: 'Bahamas', countryName: 'Bahamas' },
  { code: 'PLS', airportName: 'Turks & Caicos (PLS)', cityName: 'Turks & Caicos', countryName: 'Turks and Caicos Islands' },
  {
    code: 'SVD',
    airportName: 'St. Vincent & the Grenadines (SVD)',
    cityName: 'St. Vincent & the Grenadines',
    countryName: 'Saint Vincent and the Grenadines',
  },
  { code: 'UVF', airportName: 'St. Lucia (UVF)', cityName: 'St. Lucia', countryName: 'Saint Lucia' },
  { code: 'DXB', airportName: 'Dubai (DXB)', cityName: 'Dubai', countryName: 'United Arab Emirates' },
  { code: 'RUH', airportName: 'Riyadh (RUH)', cityName: 'Riyadh', countryName: 'Saudi Arabia' },
  { code: 'TLV', airportName: 'Tel Aviv (TLV)', cityName: 'Tel Aviv', countryName: 'Israel' },
  { code: 'CUN', airportName: 'Cancun (CUN)', cityName: 'Cancun', countryName: 'Mexico' },
  { code: 'ATL', airportName: 'Atlanta (ATL)', cityName: 'Atlanta', countryName: 'USA' },
  { code: 'BOS', airportName: 'Boston (BOS)', cityName: 'Boston', countryName: 'USA' },
  { code: 'LAS', airportName: 'Las Vegas (LAS)', cityName: 'Las Vegas', countryName: 'USA' },
  { code: 'LAX', airportName: 'Los Angeles (LAX)', cityName: 'Los Angeles', countryName: 'USA' },
  { code: 'MIA', airportName: 'Miami (MIA)', cityName: 'Miami', countryName: 'USA' },
  { code: 'JFK', airportName: 'New York (JFK)', cityName: 'New York', countryName: 'USA' },
  { code: 'MCO', airportName: 'Orlando (MCO)', cityName: 'Orlando', countryName: 'USA' },
  { code: 'SFO', airportName: 'San Francisco (SFO)', cityName: 'San Francisco', countryName: 'USA' },
  { code: 'SEA', airportName: 'Seattle (SEA)', cityName: 'Seattle', countryName: 'USA' },
  { code: 'TPA', airportName: 'Tampa (TPA)', cityName: 'Tampa', countryName: 'USA' },
  { code: 'IAD', airportName: 'Washington, D.C. (IAD)', cityName: 'Washington, D.C.', countryName: 'USA' },
]

export const matchesQuery = (tokens: string[], airport: Airport) => {
  return tokens.every((token) => Object.values(airport).some((field) => field.toLowerCase().includes(token)))
}

export const searchAirports = (query: string): Airport[] => {
  const tokens = query.trim().toLowerCase().split(/\s+/).filter(Boolean)
  return tokens.length ? airports.filter((airport) => matchesQuery(tokens, airport)) : airports
}
