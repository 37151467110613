import React from 'react'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Document } from '@contentful/rich-text-types'
import { contentTypeOf } from '@vrw/data/src/utils/contentfulPages'
import { isUrlExternal } from '../../../helpers/urlHelper'
import { Link } from '../../Link'
import { DynamicButton } from '../DynamicButton'

import { ColorTokens, DynamicPlaceholder, dynamicRichTextOptionOverrides, styled, YStack, Text } from '@red-ui/components'

export const Mark = styled(Text, {
  color: 'inherit',
  fontSize: 'unset',
})

const optionOverrides: Options = {
  ...dynamicRichTextOptionOverrides,
  renderNode: {
    ...dynamicRichTextOptionOverrides.renderNode,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const contentType = contentTypeOf(node.data?.target)
      const getContent = () => {
        if (contentType === 'dynamicButton') {
          return <DynamicButton fields={node.data?.target.fields} />
        }
        return <DynamicPlaceholder content={node.data?.target} />
      }
      return <YStack marginBottom="$2">{getContent()}</YStack>
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} external={isUrlExternal(node.data.uri)} asChild children={<Mark>{children}</Mark>} />
    ),
  },
}

export const RichTextRenderer = YStack.styleable<{ content: Document; options?: Options; color?: ColorTokens }>(
  ({ content, options = optionOverrides, ...props }, ref) => (
    <YStack {...props} ref={ref}>
      {documentToReactComponents(content, options)}
    </YStack>
  )
)
