import React from 'react'
import { Card, Text, Separator, Button } from '@red-ui/components'
import { SeatTypeInfo } from './SeatTypeInfo'
import { FlightPointsPrice } from './FlightPointsPrice'
import { getString } from '@vrw/data'

export const SeatTypeCard = () => {
  return (
    <Card width={295} backgroundColor="$white" borderWidth={1} padding="$2" marginTop="$2" marginRight="$2">
      <SeatTypeInfo />
      <Separator marginVertical="$2" />
      <Text>{getString('redSkySearch.results.card.passengers')}: 1 adult</Text>
      <Separator marginVertical="$2" />
      <FlightPointsPrice label={getString('redSkySearch.results.card.points.label.onewaytrip')} points={75000} />
      <Button aria-label={getString('redSkySearch.results.card.cta')} marginTop="$3">
        {getString('redSkySearch.results.card.cta')}
      </Button>
    </Card>
  )
}
