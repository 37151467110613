import React from 'react'
import { Button, Icon, ScrollView, Text, View } from '@red-ui/components'
import { Airport } from '../../api/searchAirports'

type Props = {
  results: Airport[]
  onSelect: (airport: Airport) => void
}

export const Results = ({ results, onSelect }: Props) => {
  return (
    <ScrollView contentContainerStyle={{ gap: '$2' }}>
      {results.map((result) => (
        <Button
          key={result.code}
          unstyled
          borderStyle="unset"
          borderWidth="$0"
          flexDirection="row"
          height="$5"
          alignItems="center"
          cursor="pointer"
          backgroundColor="$white"
          onPress={() => onSelect(result)}
          hoverStyle={{ backgroundColor: '$black2' }}
          pressStyle={{ backgroundColor: '$black3' }}>
          <View>
            <Icon.Plane color="$black10" fill="$black10" size="$2" />
          </View>
          <Text textAlign="left" color="$black10" fontSize="$4">
            {result.airportName}
          </Text>
        </Button>
      ))}
    </ScrollView>
  )
}
