import React from 'react'
import { connect } from 'react-redux'
import { Dialog as MaterialUiDialog } from '@virgin-core/components'
import { getString } from '@vrw/data'
import { doConfirmDialog, doHideDialog } from '@vrw/data/src/redux/dialog/dispatchers'
import { DispatchFunction } from '@vrw/data/src/redux/types'
import { RootState } from '../../redux/types'
import { FontFamilies, color } from '../../style/variables'
import { media } from '../../style/media'
import { Button } from '../Buttons/Button'
import IconMapper, { Icon } from '../icons/IconMapper'
import { Picture, LoadSpinner } from '../'
import { DialogProps, DialogStateProps, DialogDispatchProps } from './types'
import { PUBLIC_URL } from '../../config'

export const Dialog = ({ dialog, onClose, onConfirm, visible }: DialogProps) => {
  if (!dialog) {
    return null
  }
  const {
    bodyTextId,
    bodyTextString,
    brandLogo,
    buttonCancelTextId,
    buttonConfirmTextId,
    descriptionTextString,
    dialogTestId,
    image,
    isLoading = false,
    titleTextId,
    titleTextString,
    explicitCancelRequired,
  } = dialog
  const imageAlt = titleTextId ? getString(titleTextId) : bodyTextId ? getString(bodyTextId) : 'dialog-image'

  const responsiveBrandLogoImages = {
    mobile: {
      imgSrc: `${brandLogo}?w=72&h=72&fm=webp`,
      hiResImgSrc: `${brandLogo}?w=144&h=144&fm=webp`,
    },
    tablet: {
      imgSrc: `${brandLogo}?w=72&h=72&fm=webp`,
      hiResImgSrc: `${brandLogo}?w=144&h=144&fm=webp`,
    },
    desktop: {
      imgSrc: `${brandLogo}?w=72&h=72&fm=webp`,
      hiResImgSrc: `${brandLogo}?w=144&h=144&fm=webp`,
    },
  }

  return (
    <MaterialUiDialog
      open={visible}
      onClose={explicitCancelRequired ? undefined : onClose}
      maxWidth="lg"
      PaperProps={{ style: { borderRadius: '8px' } }}>
      <style jsx>{`
        .dialog-content {
          padding: 47px 34px;
          font-family: ${FontFamilies.barlow};
          text-align: center;
        }
        .dialog-image {
          margin-bottom: 24px;
        }
        .dialog-image img {
          max-width: 100%;
          width: 100%;
        }
        .dialog-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 1.14;
          margin-bottom: 24px;
        }
        .dialog-body {
          font-size: 18px;
          line-height: 1.6;
          margin-bottom: 24px;
        }
        .dialog-buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }
        .dialog-button {
          margin: 6px;
          flex-grow: 1;
          flex-shrink: 0;
          min-width: 182px;
        }
        .redirect-images-wrapper {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 24px;
        }
        .icon-wrapper {
          width: 58px;
        }
        @media ${media.tabletAndHigher} {
          .dialog-title {
            font-size: 28px;
          }
          .dialog-body {
            font-size: 20px;
          }
          .dialog-image {
            margin-bottom: 40px;
          }
          .dialog-button {
            flex-grow: 0;
            min-width: 182px;
          }
        }
      `}</style>
      <div className="dialog-content" data-testid={dialogTestId}>
        {image && (
          <div className="dialog-image" data-testid="dialog-image">
            <img src={image} alt={imageAlt} />
          </div>
        )}

        {brandLogo && (
          <div className="redirect-images-wrapper" data-testid="redirect-images-wrapper">
            <img
              className="virgin-logo"
              src={`${PUBLIC_URL}/img/virgin-logo-circle.svg`}
              alt="Virgin Logo"
              height={72}
              width={72}
              data-testid="virgin-logo"
            />
            <div className="icon-wrapper" data-testid="icon-wrapper">
              <IconMapper icon={Icon.ArrowRight} color={color.brandPrimary} size={16} />
            </div>
            <Picture
              altText="logo"
              width={72}
              fallbackImg={`${brandLogo}?w=72&h=72`}
              height={72}
              loading={'eager'}
              responsiveImages={responsiveBrandLogoImages}
              isWebP={true}
            />
          </div>
        )}

        {titleTextId && (
          <div className="dialog-title" data-testid="dialog-title" role="heading" aria-level={3}>
            {getString(titleTextId)}
          </div>
        )}

        {titleTextString && (
          <div className="dialog-title" data-testid="dialog-title" role="heading" aria-level={3}>
            {titleTextString}
          </div>
        )}

        {bodyTextId && (
          <div className="dialog-body" data-testid="dialog-body" role="article">
            {getString(bodyTextId)}
          </div>
        )}

        {bodyTextString && (
          <div className="dialog-body" data-testid="dialog-body" role="article">
            {bodyTextString}
          </div>
        )}

        {descriptionTextString && (
          <div className="dialog-body" data-testid="dialog-description" role="article">
            {descriptionTextString}
          </div>
        )}

        {isLoading && <LoadSpinner />}

        <div className="dialog-buttons">
          {buttonConfirmTextId && (
            <div className="dialog-button">
              <Button
                isPrimary
                text={getString(buttonConfirmTextId)}
                onClick={onConfirm}
                dataAttributes={{ testid: 'dialog-button-confirm' }}
              />
            </div>
          )}
          {buttonCancelTextId && (
            <div className="dialog-button">
              <Button text={getString(buttonCancelTextId)} onClick={onClose} dataAttributes={{ testid: 'dialog-button-cancel' }} />
            </div>
          )}
        </div>
      </div>
    </MaterialUiDialog>
  )
}

const mapStateToProps = (state: RootState): DialogStateProps => ({
  dialog: state.dialog.dialogOptions,
  visible: state.dialog.visible,
})

const mapDispatchToProps = (dispatch: DispatchFunction): DialogDispatchProps => ({
  onClose: (): void => {
    dispatch(doHideDialog())
  },
  onConfirm: (): void => {
    dispatch(doConfirmDialog())
  },
})

const DialogContainer = connect(mapStateToProps, mapDispatchToProps)(Dialog)

export default DialogContainer
