import React, { PropsWithChildren } from 'react'

import { color } from '@/style/variables'
import CurveSVG from '@/components/CurvedContainer/CurveSVG'
import { View, ViewProps } from 'tamagui'

type HeaderProps = ViewProps & {
  disableCurve?: boolean
}

const Header = ({ children, disableCurve, ...rest }: PropsWithChildren<HeaderProps>) => (
  <View background={color.darkPinkBackground} position="relative" justifyContent="flex-end" {...rest}>
    {children}
    {!disableCurve && (
      <View
        display="block"
        $desktop={{ height: '$11' }}
        $tabletPortrait={{ height: '$7' }}
        $tabletLandscape={{ height: '$7' }}
        $mobile={{ height: '$6' }}>
        <CurveSVG position={'bottom'} overlayColor={color.white} height="100%" />
      </View>
    )}
  </View>
)

export default Header
