import { Button, Card, H1, H3, Image, Paragraph, YStack } from '@red-ui/components'
import { getBalance } from '@vrw/data/src/redux/balance/balance.selectors'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { formatPoints } from '@vrw/data/src/utils/formatters'
import React, { useState } from 'react'
import { getString } from '@vrw/data'
import { Page } from '@/components'
import { useAppSelector } from '@/redux/hooks/hooks'
import { QuestionForm } from '../components/QuestionForm'
import { SideSheet } from '../components/SideSheet'
import { AutocompleteForm } from '../components/AutocompleteForm'

// note: not exactly sure what the placeholder image should be
const placeholderImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Ww8AAj8BXkQ+xPEAAAAASUVORK5CYII='

type SideSheetType = 'from' | 'to'

export function LandingPage() {
  const profile = useAppSelector(getProfileData)
  const points = useAppSelector(getBalance)
  const [mainSheetOpen, setMainSheetOpen] = useState(false)
  const [openSheet, setOpenSheet] = useState<SideSheetType | null>(null)

  const handleMainOpen = () => {
    setMainSheetOpen(true)
  }

  const handleMainClose = () => {
    if (openSheet) handleSubSheetClose()
    setMainSheetOpen(false)
  }

  const handleSubSheetClose = () => {
    setOpenSheet(null)
  }

  const onSheetOpen = (type: SideSheetType | null) => () => {
    setOpenSheet(type)
  }

  return (
    <Page title={getString('redSkySearch.title')}>
      <SideSheet open={mainSheetOpen} onClose={handleMainClose} ariaLabel={getString('redSkySearch.sidesheet.questions.ariaLabel')}>
        <QuestionForm onClose={handleMainClose} onFromInputOpen={onSheetOpen('from')} onToInputOpen={onSheetOpen('to')} />
      </SideSheet>
      <SideSheet
        open={openSheet === 'from'}
        onClose={handleSubSheetClose}
        hasOverlay={false}
        ariaLabel={getString('redSkySearch.sidesheet.departure.ariaLabel')}>
        <AutocompleteForm
          onClose={handleSubSheetClose}
          title={getString('redSkySearch.question.departure.label')}
          info={getString('redSkySearch.question.departure.input.info')}
          onSelect={handleSubSheetClose}
        />
      </SideSheet>
      <SideSheet
        open={openSheet === 'to'}
        onClose={handleSubSheetClose}
        hasOverlay={false}
        ariaLabel={getString('redSkySearch.sidesheet.destination.ariaLabel')}>
        <AutocompleteForm
          onClose={handleSubSheetClose}
          title={getString('redSkySearch.question.destination.label')}
          info={getString('redSkySearch.question.destination.input.info')}
          onSelect={handleSubSheetClose}
        />
      </SideSheet>
      <YStack tag="section" gap="$5" paddingHorizontal="$4" paddingVertical="$6">
        <YStack alignItems="center" gap="$2">
          <H1>{getString('redSkySearch.landing.title')}</H1>
          <Paragraph>{getString('redSkySearch.landing.description')}</Paragraph>
        </YStack>
        <YStack alignItems="center" gap="$2">
          <H3>{getString('redSkySearch.landing.book.title')}</H3>
          <Card backgroundColor="$black1" maxWidth="500px">
            <Card.Header padding="$0" overflow="hidden">
              <Image
                source={{
                  uri: placeholderImage,
                  height: 300,
                }}
                alt="personal image"
              />
            </Card.Header>
            <Card.Footer flexDirection="column" gap="$2" padded>
              <Paragraph tag="strong">
                {getString('redSkySearch.landing.book.welcome').replace('{{name}}', profile?.firstName || '')}
              </Paragraph>
              <Paragraph color="$red6" tag="strong">
                {getString('redSkySearch.landing.book.points').replace('{{points}}', formatPoints({ points: points.usable || 0 }))}
              </Paragraph>
              <Paragraph>{getString('redSkySearch.landing.book.description')}</Paragraph>
              <Button padded onPress={handleMainOpen}>
                {getString('redSkySearch.landing.book.cta')}
              </Button>
            </Card.Footer>
          </Card>
        </YStack>
      </YStack>
    </Page>
  )
}
