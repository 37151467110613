import React from 'react'
import { Accordion, View, YStack, ScrollView } from '@red-ui/components'
import { Header } from './Header'
import { SeatTypeCard } from './SeatTypeCard'
import { FlightPointsPrice } from './FlightPointsPrice'
import { getString } from '@vrw/data'

export type Props = {
  value: string
  isActive: boolean
}

const CARD_WIDTH = 343

export const FlightDataCard = ({ value, isActive }: Props) => {
  return (
    <Accordion.Item
      value={value}
      backgroundColor="$white"
      borderWidth={1}
      borderRadius="$4"
      borderColor={isActive ? '$black10' : '$black2'}>
      <Accordion.Header>
        <Accordion.Trigger
          borderRadius="$4"
          cursor="pointer"
          backgroundColor="$white"
          unstyled
          hoverStyle={{ backgroundColor: '$white' }}
          focusStyle={{ backgroundColor: '$white' }}>
          <YStack width={CARD_WIDTH} padding="$2">
            <Header />
            {!isActive && (
              <View marginTop="$2">
                <FlightPointsPrice label={getString('redSkySearch.results.card.points.label.from')} points={75000} />
              </View>
            )}
          </YStack>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.HeightAnimator borderRadius="$4" animation="quick" backgroundColor="$white">
        <Accordion.Content
          borderRadius="$4"
          unstyled
          width={CARD_WIDTH}
          animation="quick"
          exitStyle={{ opacity: 0 }}
          backgroundColor="$white">
          <ScrollView horizontal paddingLeft="$2" marginBottom="$2">
            <SeatTypeCard />
            <SeatTypeCard />
            <SeatTypeCard />
          </ScrollView>
        </Accordion.Content>
      </Accordion.HeightAnimator>
    </Accordion.Item>
  )
}
