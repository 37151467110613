import React, { FC, ReactNode } from 'react'

import { layout, Spacing, color, spacing } from '@/style/variables'
import { View } from 'tamagui'

type ContentProps = {
  children: ReactNode
  backgroundColor?: color
  verticalOffset?: Spacing
}

const RewardInfoWrapper: FC<ContentProps> = ({ children, backgroundColor, verticalOffset }) => {
  const verticalOffsets = {
    ...spacing.zero,
    ...verticalOffset,
  }

  return (
    <View
      margin={'auto'}
      backgroundColor={backgroundColor ?? 'inherit'}
      borderWidth={1}
      borderRadius={'$4'}
      borderStyle={'solid'}
      borderColor={'#e3e3e3'}
      paddingBottom={'$5'}
      width={'100%'}
      $gtDesktop={{ marginTop: verticalOffsets.desktop }}
      $gtTabletPortrait={{ width: layout.heroWidth.tablet, marginTop: verticalOffsets.tablet }}
      $gtTabletLandscape={{ width: layout.heroWidth.desktop }}
      $mobile={{
        borderRadius: '$0',
        paddingBottom: '$8',
        marginTop: verticalOffsets.mobile,
        borderWidth: 0,
      }}>
      {children}
    </View>
  )
}

export default RewardInfoWrapper
