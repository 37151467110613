import { View, Image, YStack, XStack, Text, Separator } from '@red-ui/components'
import React from 'react'
import { Chip } from '@/components'
import { getString } from '@vrw/data'

export const Header = () => {
  return (
    <View padding="$0">
      <XStack justifyContent="space-between">
        <XStack>
          <Image
            source={{
              uri: '/img/virgin_atlantic_logo.png',
              height: 35,
              width: 35,
            }}
            aria-label={getString('redSkySearch.results.card.logo.alt')}
            marginRight="$1"
          />
          <YStack alignItems="flex-start">
            <Text fontWeight="600">09:25 - 15:34</Text>
            <Text color="$black5">{getString('redSkySearch.results.card.airline')}</Text>
          </YStack>
        </XStack>
        <Text fontWeight="600">Fri 14 Feb</Text>
      </XStack>
      <YStack marginTop="$2" marginBottom="$3">
        <XStack alignItems="center" justifyContent="space-between">
          <Text fontWeight="600" marginRight="$3">
            MAN
          </Text>
          <Separator />
          <Chip.Root marginHorizontal="$2" paddingHorizontal="$2" minWidth="unset" backgroundColor="$black1" shadowOpacity={0}>
            <Chip.Text color="$black10" fontWeight="400" fontSize="$2">
              Direct
            </Chip.Text>
          </Chip.Root>
          <Separator />
          <Text marginLeft="$3" fontWeight="600" textAlign="right">
            JFK
          </Text>
        </XStack>
        <XStack justifyContent="space-between">
          <Text>09:25</Text>
          <Text fontSize="$2" marginTop="$0.5">
            6h 59m
          </Text>
          <Text textAlign="right">15:34</Text>
        </XStack>
      </YStack>
      <Separator />
    </View>
  )
}
