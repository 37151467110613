import { createSelector } from '@reduxjs/toolkit'
import { Feature, FeatureValue, FeatureValueType } from './features.types'
import { RedDataState } from '../types'

const getFeatureState = (state: RedDataState) => state.featureFlags

export const getFeatureIsLoading = createSelector(getFeatureState, (featureFlags) => featureFlags.isLoading)

export const getAllFeatures = createSelector(getFeatureState, (featureFlags) => featureFlags.features || [])

export const getFeatureValueType = (feature: Feature) => {
  const valueType = Object.keys(feature.value)[0] as FeatureValueType
  return Object.values(FeatureValueType).includes(valueType) ? valueType : undefined
}
/**
 * @deprecated use selectFeatureById instead
 */
export const getFeatureById = (featureFlags: Feature[], featureId: string): FeatureValue | undefined => {
  const feature = featureFlags?.find((feature) => feature.name === featureId)
  const valueType = feature && getFeatureValueType(feature)
  return valueType && feature.value[valueType]
}

/**
 * NOTE: Please use useFeatureSelector instead of this function
 */
export const selectFeatureById = createSelector([getAllFeatures, (_: unknown, id: string) => id], getFeatureById)

export const useFeatureSelector = (featureId: string) => (state: RedDataState) => selectFeatureById(state, featureId)
