import React from 'react'
import { DynamicLayout, Spinner } from '@red-ui/components'
import type { Entry } from 'contentful'
import { convertRewardToCard } from '@vrw/data/src/utils/convertRewardToCard'
import { useRewardGroups } from '../../../query/rewardGroups'
import { IntroCarouselBlockPrivate } from '../../ContentfulBlock/IntroCarouselBlock'
import {
  IntroCarouselBlock,
  IntroCarouselType,
  type IntroCarouselBlockProps,
} from '../../ContentfulBlock/IntroCarouselBlock/IntroCarouselBlock'
import { CardTypes } from '../../ContentfulBlock/types'

export type DynamicCarouselFields = {
  fields: Pick<IntroCarouselBlockProps, 'title'> & { sliderContentType: CardTypes; header?: Entry<IntroCarouselBlockProps['header']> }
}

const RewardGroupContainer = ({ header, title }: React.ComponentProps<typeof IntroCarouselBlockPrivate>) => {
  const { data: rewardGroups, isPending } = useRewardGroups()

  return (
    <>
      {isPending ? (
        <Spinner size="large" color="$red6" />
      ) : (
        rewardGroups?.map((group) => (
          <IntroCarouselBlock
            key={group.id}
            items={() => convertRewardToCard(group.rewards, group.id)}
            doGetItems={() => ({})}
            isLoading={false}
            type={IntroCarouselType.REWARD}
            title={title}
            header={header}
            disableWishlist
          />
        ))
      )}
    </>
  )
}

export const DynamicCarousel = ({ fields }: DynamicCarouselFields) => {
  let Wrapper = IntroCarouselBlockPrivate
  if (fields.sliderContentType === CardTypes.ELIGIBLE_BENEFITS) Wrapper = RewardGroupContainer

  return (
    <DynamicLayout isSection fields={{}}>
      <Wrapper type={fields.sliderContentType} title={fields.title} header={fields?.header?.fields} />
    </DynamicLayout>
  )
}
