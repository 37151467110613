import React from 'react'
import { AnimatePresence, Button, View } from '@red-ui/components'
import { getString } from '@vrw/data'

export type Props = {
  isBackVisible: boolean
  onContinue: () => void
  onBack: () => void
}

export const Footer = ({ isBackVisible, onContinue, onBack }: Props) => (
  <View gap="$2">
    <Button padded onPress={onContinue}>
      {getString('actions.continue')}
    </Button>
    <AnimatePresence>
      {isBackVisible && (
        <View animation="200ms" exitStyle={{ opacity: 0 }} enterStyle={{ opacity: 0 }}>
          <Button padded onPress={onBack} theme="secondary">
            {getString('actions.back')}
          </Button>
        </View>
      )}
    </AnimatePresence>
  </View>
)
