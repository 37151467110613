import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getString } from '@vrw/data'
import { getVoucherById } from '@vrw/data/src/redux/wallet/selectors'
import { formatIsoDateToDateWithMonthString } from '@vrw/data/src/utils/formatters'
import { RootState } from '@/redux/types'
import { PATHS } from '@/router/paths'
import { HelpCTA } from '@/components'
import { Stars } from '@/components/icons/Stars'
import { Content } from '@/components/layout'
import { Page } from '@/components/Page'
import H2Header from '@/components/typography/Red/H2Header'
import Header from '@/components/RewardDetails/Header'
import VoucherSummary from '@/components/VoucherSummary'
import { UrlCodeVoucher } from '@/components/UrlCodeVoucher'
import { media } from '@/style/media'
import { color } from '@/style/variables'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'

import { View } from '@red-ui/components'

const Voucher: FC = () => {
  let { voucherId } = useParams<{ voucherId: string }>()
  const voucher = useSelector((state: RootState) => getVoucherById(state, voucherId))
  const navigate = useRedNavigate()
  // TODO - Figure out what the fallback UX should be for used vouchers and incorrect urls
  useEffect(() => {
    if (!voucher) navigate(PATHS.MY_REWARDS_VOUCHERS)
  })

  if (!voucher) return <></>

  const {
    content: {
      title,
      brandDetails: { brandLogo },
    },
    validFrom,
    validTo,
    image,
    code,
  } = voucher
  return (
    <>
      <style jsx>{`
        @media ${media.mobileAndLower} {
          .content-wrapper :global(.content) {
            margin: 0;
          }
        }
      `}</style>
      <View position="absolute" top={0} left={0} right={0}>
        <Header>
          <View height={300} $gtMobile={{ height: 260 }} $gtTabletPortrait={{ height: 330 }} />
        </Header>
      </View>
      <Page testId="page-voucher" title={`${title} | Vouchers | My Rewards | Virgin`}>
        <View className="content-wrapper">
          <Content style={{ position: 'relative' }}>
            <View $gtDesktop={{ height: 60 }} height={40} justifyContent="center" margin="auto" marginTop={'$2'}>
              <Stars />
            </View>
            <H2Header
              fontStyle="italic"
              marginBottom={{
                mobile: 48,
                tablet: 64,
                desktop: 64,
              }}
              marginTop={{ tablet: 16, desktop: 24 }}
              textAlign="center"
              weight={600}>
              {getString('myRewards.voucher.title').toUpperCase()}
            </H2Header>
            <View
              borderRadius={'$0'}
              backgroundColor={color.white}
              paddingTop={'$3'}
              paddingBottom={'$5'}
              $gtTabletPortrait={{
                borderRadius: '$4',
                borderWidth: 1,
                borderStyle: `solid`,
                borderColor: color.secondaryButtonBorder,
                margin: 'auto',
                maxWidth: 872,
                paddingTop: '$true',
              }}>
              {voucher.url ? (
                <UrlCodeVoucher voucher={voucher} />
              ) : (
                <VoucherSummary
                  title={title}
                  brandLogoUrl={brandLogo}
                  validFrom={formatIsoDateToDateWithMonthString(validFrom, true)}
                  validTo={formatIsoDateToDateWithMonthString(validTo, true)}
                  qrImage={image}
                  code={code}
                />
              )}
            </View>
            <HelpCTA />
          </Content>
        </View>
      </Page>
    </>
  )
}

export { Voucher }
